import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Silk } from 'bv-components';
import withOutcome from 'sportsbook-with-outcome';
import { formatPrice } from 'sportsbook-helpers';
import { PreviousPrice } from 'sportsbook-components';

import Price from './price';
import Form from './form';

const outcomeClasses = ({ id, suspended, selected }) => classnames(
  'bvs-button-sport is-square upcoming-races__outcome-price',
  'outcome ',
  `outcome-${id}`,
  {
    disabled: suspended,
    active: selected,
  },
);

const isSuspended = ({ os }) => os === 2;

const Outcome = ({
  id, outcome, marketSuspended, selected, toggleBetslip, enhancedOutcome,
}) => (
  <li className="upcoming-races__outcome">
    <Silk
      silk={outcome.silk}
      trap={outcome.trap}
      className={
      classnames('upcoming-races__outcome-image', {
        'is-silk': outcome.silk,
        'is-trap': outcome.trap,
      })
    }
    />
    <div className="upcoming-races__outcome-info">
      <p className="upcoming-races__outcome-title">{outcome.desc}</p>
      <p className="upcoming-races__outcome-subtitle">{outcome.jockey}</p>
      <Form form={outcome.form} />
    </div>
    <div className="upcoming-races__outcome-button-wrapper">
      <button
        type="button"
        className={outcomeClasses({
          id,
          suspended: marketSuspended || isSuspended(outcome),
          selected,
        })}
        onClick={() => toggleBetslip({ clickSource: 'upcoming-races-widget' })}
      >
        <Price
          fractionalEnhancedOdds={
            (enhancedOutcome && formatPrice(enhancedOutcome.fractionalEnhancedOdds)) || null
          }
          price={formatPrice(outcome.pr)}
        />
      </button>
      <PreviousPrice
        price={outcome.pr}
        previousPrice={outcome.pp}
        antePreviousPrice={outcome.app}
      />
    </div>
  </li>
);

Outcome.propTypes = {
  desc: PropTypes.string.isRequired,
  jockey: PropTypes.string.isRequired,
  silk: PropTypes.string.isRequired,
  trap: PropTypes.string,
  form: PropTypes.string,
  pr: PropTypes.string.isRequired,
  pp: PropTypes.string.isRequired,
  app: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  marketSuspended: PropTypes.bool.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  enhancedOutcome: PropTypes.shape({
    outcomeId: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    minStake: PropTypes.number.isRequired,
    maxStake: PropTypes.number.isRequired,
    fractionalEnhancedOdds: PropTypes.string.isRequired,
  }),
};

Outcome.defaultProps = {
  enhancedOutcome: null,
  trap: null,
  form: null,
};

export default withOutcome(Outcome);
